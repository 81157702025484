define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-step", ["exports", "discourse-common/utils/decorators", "@ember/component", "discourse-common/lib/get-url", "@ember/template", "@ember/runloop", "discourse/lib/text", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard", "@ember/object/computed", "discourse-common/lib/later", "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-composer-editor"], function (_exports, _decorators, _component, _getUrl, _template, _runloop, _text, _customWizard, _computed, _later, _customWizardComposerEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj, _init, _init2, _init3, _init4;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const uploadStartedEventKeys = ["upload-started"];
  const uploadEndedEventKeys = ["upload-success", "upload-error", "upload-cancelled", "uploads-cancelled", "uploads-aborted", "all-uploads-complete"];
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("step.index", "wizard.required"), _dec2 = (0, _decorators.default)("step.index", "step.displayIndex", "wizard.totalSteps", "wizard.completed"), _dec3 = (0, _decorators.default)("step.index"), _dec4 = (0, _decorators.default)("step.banner"), _dec5 = (0, _decorators.default)("step.id"), _dec6 = (0, _decorators.default)("step.fields.[]"), _dec7 = (0, _decorators.default)("step.fields.[]"), _dec8 = (0, _decorators.observes)("step.id"), _dec9 = (0, _decorators.observes)("step.message"), _dec10 = (0, _decorators.default)("step.index", "wizard.totalSteps"), _dec11 = (0, _decorators.default)("step.fields"), (_obj = {
    classNameBindings: [":wizard-step", "step.id"],
    saving: null,
    init() {
      this._super(...arguments);
      this.set("stylingDropdown", {});
    },
    didReceiveAttrs() {
      this._super(...arguments);
      (0, _text.cook)(this.step.translatedTitle).then(cookedTitle => {
        this.set("cookedTitle", cookedTitle);
      });
      (0, _text.cook)(this.step.translatedDescription).then(cookedDescription => {
        this.set("cookedDescription", cookedDescription);
      });
      uploadStartedEventKeys.forEach(key => {
        this.appEvents.on(`${_customWizardComposerEditor.wizardComposerEdtiorEventPrefix}:${key}`, () => {
          this.set("uploading", true);
        });
      });
      uploadEndedEventKeys.forEach(key => {
        this.appEvents.on(`${_customWizardComposerEditor.wizardComposerEdtiorEventPrefix}:${key}`, () => {
          this.set("uploading", false);
        });
      });
    },
    didInsertElement() {
      this._super(...arguments);
      this.autoFocus();
    },
    showQuitButton: (index, required) => index === 0 && !required,
    showNextButton: (0, _computed.not)("step.final"),
    showDoneButton: (0, _computed.alias)("step.final"),
    btnsDisabled: (0, _computed.or)("saving", "uploading"),
    showFinishButton: (index, displayIndex, total, completed) => {
      return index !== 0 && displayIndex !== total && completed;
    },
    showBackButton: index => index > 0,
    bannerImage(src) {
      if (!src) {
        return;
      }
      return (0, _getUrl.default)(src);
    },
    bannerAndDescriptionClass(id) {
      return `wizard-banner-and-description wizard-banner-and-description-${id}`;
    },
    primaryButtonIndex(fields) {
      return fields.length + 1;
    },
    secondaryButtonIndex(fields) {
      return fields.length + 2;
    },
    _stepChanged() {
      this.set("saving", false);
      this.autoFocus();
    },
    _handleMessage: function () {
      const message = this.get("step.message");
      this.showMessage(message);
    },
    barStyle(displayIndex, totalSteps) {
      let ratio = parseFloat(displayIndex) / parseFloat(totalSteps - 1);
      if (ratio < 0) {
        ratio = 0;
      }
      if (ratio > 1) {
        ratio = 1;
      }
      return (0, _template.htmlSafe)(`width: ${ratio * 200}px`);
    },
    includeSidebar(fields) {
      return !!fields.findBy("show_in_sidebar");
    },
    autoFocus() {
      (0, _later.default)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          if ($(".invalid .wizard-focusable").length) {
            this.animateInvalidFields();
          }
        });
      });
    },
    animateInvalidFields() {
      (0, _runloop.schedule)("afterRender", () => {
        let $invalid = $(".invalid .wizard-focusable");
        if ($invalid.length) {
          $([document.documentElement, document.body]).animate({
            scrollTop: $invalid.offset().top - 200
          }, 400);
        }
      });
    },
    advance() {
      this.set("saving", true);
      this.get("step").save().then(response => {
        (0, _customWizard.updateCachedWizard)(_customWizard.default.build(response["wizard"]));
        if (response["final"]) {
          _customWizard.default.finished(response);
        } else {
          this.goNext(response);
        }
      }).catch(() => this.animateInvalidFields()).finally(() => this.set("saving", false));
    },
    actions: {
      quit() {
        this.get("wizard").skip();
      },
      done() {
        this.send("nextStep");
      },
      showMessage(message) {
        this.sendAction(message);
      },
      stylingDropdownChanged(id, value) {
        this.set("stylingDropdown", {
          id,
          value
        });
      },
      exitEarly() {
        const step = this.step;
        step.validate();
        if (step.get("valid")) {
          this.set("saving", true);
          step.save().then(() => this.send("quit")).finally(() => this.set("saving", false));
        } else {
          this.autoFocus();
        }
      },
      backStep() {
        if (this.saving) {
          return;
        }
        this.goBack();
      },
      nextStep() {
        if (this.saving) {
          return;
        }
        this.step.validate();
        if (this.step.get("valid")) {
          this.advance();
        } else {
          this.autoFocus();
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "showQuitButton", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "showQuitButton"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "showFinishButton", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "showFinishButton"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "showBackButton", [_dec3], (_init3 = Object.getOwnPropertyDescriptor(_obj, "showBackButton"), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "bannerImage", [_dec4], Object.getOwnPropertyDescriptor(_obj, "bannerImage"), _obj), _applyDecoratedDescriptor(_obj, "bannerAndDescriptionClass", [_dec5], Object.getOwnPropertyDescriptor(_obj, "bannerAndDescriptionClass"), _obj), _applyDecoratedDescriptor(_obj, "primaryButtonIndex", [_dec6], Object.getOwnPropertyDescriptor(_obj, "primaryButtonIndex"), _obj), _applyDecoratedDescriptor(_obj, "secondaryButtonIndex", [_dec7], Object.getOwnPropertyDescriptor(_obj, "secondaryButtonIndex"), _obj), _applyDecoratedDescriptor(_obj, "_stepChanged", [_dec8], Object.getOwnPropertyDescriptor(_obj, "_stepChanged"), _obj), _applyDecoratedDescriptor(_obj, "_handleMessage", [_dec9], (_init4 = Object.getOwnPropertyDescriptor(_obj, "_handleMessage"), _init4 = _init4 ? _init4.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init4;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "barStyle", [_dec10], Object.getOwnPropertyDescriptor(_obj, "barStyle"), _obj), _applyDecoratedDescriptor(_obj, "includeSidebar", [_dec11], Object.getOwnPropertyDescriptor(_obj, "includeSidebar"), _obj)), _obj)));
});